import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import meta from "../Image/meta.png";
import walletconnect from "../Svg/walletconnect.svg";

import Dropdown from "react-bootstrap/Dropdown";
import Minting_Navbar from "./Minting_navbar";
import { useStoreState } from "easy-peasy";
import axios from "axios";
import { toast } from "react-toastify";
import { get_Minting_Contract } from "../../Web3/web3";
import duck_image21 from "../Image/duck_image.png";
import metaspin from "../Image/metaspin.png";
import coinstore from "../Image/coinstore.png";
import bitget from "../Image/bitget.png";
import game_station from "../Image/gamestation.png";
import stealth from "../Image/stealth.png";
import team from "../Image/team.png";
import { Link } from "react-router-dom";
import admin from '../Image/admin_image.jpg';
import profile from "../Image/profile.jpg";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);

function Home() {
  const [show2, setShow2] = useState(false);
  const api_key = useStoreState((state) => state.email_api_key);
  const user = useStoreState((state) => state.user);
  const [email, setEmail] = useState("");
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [open, setOpen] = useState(false);
  const [wallet, setWallet] = useState();
  const [maxperWallet, setMaxPerWAllet] = useState("");

  useEffect(() => {
    const init = async () => {
      const nft = await get_Minting_Contract();
      const mat = await nft.methods.maxPerWallet().call();
      setMaxPerWAllet(mat);
    };
    init();
  }, [user]);

  const Send_Email = async (e) => {
    try {
      setOpen(true);
      axios({
        method: "post",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "api-key": `${api_key}`,
        },
        data: {
          sender: {
            name: `${email}`,
            email: `${email}`,
          },
          to: [
            {
              email: `mint@thedrd.xyz`,
              name: ".",
            },
          ],
          subject: "Wallet address",
          htmlContent: `${wallet}`,
        },
      })
        .then((res) => {
          if (res.status && res.status == 201) {
            notify("Email sent");
            setOpen(false);
          }
        })
        .catch((er) => {
          setOpen(false);
        });
    } catch (error) {
      setOpen(false);
    }
  };
  return (
    <>
    <div className="container-kws_contact">
      <section>
        <div className="d-f a-i-c j-c-s-b">
          <div className="">
            <Link to="/">
              {" "}
              <button className=" b-n f-w-800 f-s-1_5  l-h-1 b-5 pa-0_5 b_y_c  b-r-10 b-c-t bg_b_c ">
                {" "}
                <span className="t_g f-w-600"> home</span>
              </button>
            </Link>
          </div>
          <div className="m-t-3 m-b-2">
            <button className="b-c-t b-5 f-w-800 f-s-2_5 p-x-2 p-y-1 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
              {" "}
              <span className="t_g f-w-600"> meet the team</span>
            </button>
          </div>
          <div className="w-3"></div>
        </div>
        <div className="">
          <div className="row">
            <div className="col-6 col-sm-6 col-md-3 m-b-1">
              <a href="https://twitter.com/CarlDawkinz" target="_blank">
                <button className="b-c-t b-5 f-w-800 f-s-2_5  l-h-1  b-r-20 b_y_c w-100 bg_b_c m-b-1  m-a d-b">
                  <img src={profile} className="b-r-10 w-100" alt="" />
                </button>
                <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5  l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                  {" "}
                  <span className="t_g f-w-600"> carl dawkinz</span>
                </button>
                <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                  {" "}
                  <span className="t_g f-w-600"> founder</span>
                </button>
              </a>
            </div>
            <div className="col-6 col-sm-6 col-md-3 m-b-1">
              <a href="" target="_blank">
                <button className="b-c-t b-5 f-w-800 f-s-2_5  l-h-1  b-r-20 b_y_c w-100 bg_b_c m-b-1  m-a d-b">
                  <img src={admin} className="b-r-10 w-100" alt="" />
                </button>
                <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5  l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                  {" "}
                  <span className="t_g f-w-600"> prison wallet</span>
                </button>
                <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                  {" "}
                  <span className="t_g f-w-600"> admin</span>
                </button>
              </a>
            </div>
            <div className="col-6 col-sm-6 col-md-3 m-b-1">
              <button className="b-c-t b-5 f-w-800 f-s-2_5  l-h-1  b-r-20 b_y_c w-100 bg_b_c m-b-1  m-a d-b">
                <img src={team} className="b-r-10 w-100" alt="" />
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5  l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> jabe</span>
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> right hand</span>
              </button>
            </div>
            <div className="col-6 col-sm-6 col-md-3 m-b-1">
              <button className="b-c-t b-5 f-w-800 f-s-2_5  l-h-1  b-r-20 b_y_c w-100 bg_b_c m-b-1  m-a d-b">
                <img src={team} className="b-r-10 w-100" alt="" />
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5  l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> liam dawkins</span>
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> community manager</span>
              </button>
            </div>
            <div className="col-6 col-sm-6 col-md-3 m-b-1">
              <button className="b-c-t b-5 f-w-800 f-s-2_5  l-h-1  b-r-20 b_y_c w-100 bg_b_c m-b-1  m-a d-b">
                <img src={team} className="b-r-10 w-100" alt="" />
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5  l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> crypto fab</span>
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> social tech</span>
              </button>
            </div>
            <div className="col-6 col-sm-6 col-md-3 m-b-1">
              <button className="b-c-t b-5 f-w-800 f-s-2_5  l-h-1  b-r-20 b_y_c w-100 bg_b_c m-b-1  m-a d-b">
                <img src={team} className="b-r-10 w-100" alt="" />
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5  l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> undoxxed</span>
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> dev team</span>
              </button>
            </div>
            <div className="col-6 col-sm-6 col-md-3 m-b-1">
              <button className="b-c-t b-5 f-w-800 f-s-2_5  l-h-1  b-r-20 b_y_c w-100 bg_b_c m-b-1  m-a d-b">
                <img src={team} className="b-r-10 w-100" alt="" />
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5  l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> undoxxed</span>
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> socials</span>
              </button>
            </div>
            <div className="col-6 col-sm-6 col-md-3 m-b-1">
              <button className="b-c-t b-5 f-w-800 f-s-2_5  l-h-1  b-r-20 b_y_c w-100 bg_b_c m-b-1  m-a d-b">
                <img src={team} className="b-r-10 w-100" alt="" />
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5  l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> undoxxed</span>
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> marketing</span>
              </button>
            </div>
            <div className="col-6 col-sm-6 col-md-3 m-b-1">
              <button className="b-c-t b-5 f-w-800 f-s-2_5  l-h-1  b-r-20 b_y_c w-100 bg_b_c m-b-1  m-a d-b">
                <img src={team} className="b-r-10 w-100" alt="" />
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5  l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> undoxxed</span>
              </button>
              <button className="b-c-t b-5 f-w-800 w-100 f-s-1_5 pa-0_5 l-h-1  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> art and design</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
}

export default Home;
