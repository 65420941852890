import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import meta from "../Image/meta.png";
import walletconnect from "../Svg/walletconnect.svg";

import Dropdown from "react-bootstrap/Dropdown";
import Minting_Navbar from "./Minting_navbar";
import { useStoreState } from "easy-peasy";
import axios from "axios";
import { toast } from "react-toastify";
import { get_Minting_Contract } from "../../Web3/web3";
import duck_image21 from "../Image/duck_image.png";
import metaspin from "../Image/metaspin.png";
import coinstore from "../Image/coinstore.png";
import bitget from "../Image/bitget.png";
import game_station from "../Image/gamestation.png";
import stealth from "../Image/stealth.png";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);

function Home() {
  const [show2, setShow2] = useState(false);
  const api_key = useStoreState((state) => state.email_api_key);
  const user = useStoreState((state) => state.user);
  const [email, setEmail] = useState("");
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [open, setOpen] = useState(false);
  const [wallet, setWallet] = useState();
  const [maxperWallet, setMaxPerWAllet] = useState("");

  useEffect(() => {
    const init = async () => {
      const nft = await get_Minting_Contract();
      const mat = await nft.methods.maxPerWallet().call();
      setMaxPerWAllet(mat);
    };
    init();
  }, [user]);

  const Send_Email = async (e) => {
    try {
      setOpen(true);
      axios({
        method: "post",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "api-key": `${api_key}`,
        },
        data: {
          sender: {
            name: `${email}`,
            email: `${email}`,
          },
          to: [
            {
              email: `mint@thedrd.xyz`,
              name: ".",
            },
          ],
          subject: "Wallet address",
          htmlContent: `${wallet}`,
        },
      })
        .then((res) => {
          if (res.status && res.status == 201) {
            notify("Email sent");
            setOpen(false);
          }
        })
        .catch((er) => {
          setOpen(false);
        });
    } catch (error) {
      setOpen(false);
    }
  };
  return (
    <>
    <div className="container-kws_contact">
      <section>
        <Minting_Navbar />
        <div className="t-a-c m-q-f-s-12 l-h-1 txt_shadow t_g m-t-1  f-w-600 ">
          COMING SOON
        </div>
        {/* <div className="t-a-c f-s-2 t_g m-t-1 f-w-600 ">COMING SOON</div> */}
        <div className="row m-t-_2">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6  m-a">
            {" "}
            <div className="">
              <img src={duck_image21} className=" d-b m-a" />
            </div>
          </div>
        </div>
        <div className="m-q-d-f-900 a-i-c j-c-c m-t-_2">
          <div className="m-q-m-r-1">
            <button
              className="b-c-t b-5 f-w-800 f-s-2 w-21 m-b-2 z-i-9 m-q-m-t-3-900 m-q-w-100 b-r-10 b_y_c bg_b_c  m-a d-b"
              onClick={() => Send_Email()}
            >
              <span className="t_g f-w-600"> sign up for whitelist</span>
            </button>
          </div>
          <div className="">
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              className="b-c-t b-5 f-w-800 f-s-1_25 pa-0_5 w-20 m-b-2 b-r-10 w-20 m-q-w-100 b_y_c bg_b_c  m-a d-b"
              placeholder="Enter email..."
            />
          </div>
        </div>
        <div className="t_g f-s-2 f-w-600 t-a-c ">PARTNERED WITH</div>
        <div className="d-f a-i-c j-c-c flex-wrap ">
          <div className="m-r-3">
            <a href="https://metaspins.com/" target="_blank">
              <img src={metaspin} className="w-11 m-y-1" />
            </a>
          </div>
          <div className="m-r-3">
            <a href="https://www.gamestation.io/" target="_blank">
              <img src={game_station} className="w-3 m-y-1" />
            </a>
          </div>
          <div className="m-r-3">
            <a href="https://www.coinstore.com/#/" target="_blank">
              <img src={coinstore} className="w-11 m-y-1" />
            </a>
          </div>
          <div className="m-r-3">
            <a href="https://stealthex.io/" target="_blank">
              <img src={stealth} className="w-11 m-y-1" />
            </a>
          </div>
          <div className="">
            <a href="https://www.bitget.com/" target="_blank">
              <img src={bitget} className="w-9 m-y-1" />
            </a>
          </div>
        </div>

        {/* <div className="m-q-d-f j-c-s-b  ">
          <div className="t-a-c">
            <div className="t_g">PARTNERED WITH</div>
            <div className="">Metaspins</div>
          </div>
          <div className="t-a-c">
            <div className="t_g m-q-b-d-n">PARTNERED WITH</div>
            <div className="">Metaspins</div>
          </div>
        </div> */}
        {/* <div className="t-a-c m-b-2">CONTRACT ADDRESS : 0x423...23rd3</div> */}

        {/* <div className="row m-t-3">
          <div className="col-12 col-sm-4">
            <input
              type="text"
              onChange={(e) => setWallet(e.target.value)}
              className="b-c-t b-5 f-w-800 f-s-1_25 pa-0_5 w-100 m-b-2 b-r-10 b_y_c bg_b_c  m-a d-b"
              placeholder="Enter wallet address..."
            />
          </div>
          <div className="col-12 col-sm-4">
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              className="b-c-t b-5 f-w-800 f-s-1_25 pa-0_5 w-100 m-b-2 b-r-10 b_y_c bg_b_c  m-a d-b"
              placeholder="Enter email..."
            />
          </div>
          <div className="col-12 col-sm-4">
            {" "}
            <button
              className="b-c-t b-5 f-w-800 f-s-1_25 w-100 m-b-2 pa-0_5 b-r-10 b_y_c bg_b_c  m-a d-b"
              onClick={() => Send_Email()}
            >
              {" "}
              <span className="t_g f-w-600">Signup </span>
            </button>{" "}
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-sm-6 col-md-6">
            {" "}
            <a href="https://duckracederby.xyz" target="_blank">
              <button className="b-c-t b-5 f-w-800 f-s-1_25 w-21 m-b-2 b-r-10 b_y_c bg_b_c  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> WWW.DUCKRACEDERBY.XYZ </span>
              </button>{" "}
            </a>
          </div>
          <div className="col-12 col-sm-6 col-md-6">
            <a
              href="https://drive.google.com/file/d/1Ig7rl49LowS_qbbdOUXHaT_WPLrSctf3/view?usp=sharing"
              target="_blank"
            >
              <button className="b-c-t b-5 f-w-800 f-s-1_25 w-21 m-b-2  b-r-10 b_y_c bg_b_c  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> WHITEPAPER </span>
              </button>{" "}
            </a>
          </div>
          <Modal show={show2} onHide={handleClose2} className="">
            <div className="">
              <Modal.Header closeButton>
                <Modal.Title className="t-a-l m-a c-i">
                  Please Connect Your Wallet
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
                  <img src={meta} alt="" className="w-5" />
                  <img src={walletconnect} alt="" className="w-5" />
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div> */}
      </section>
      </div>
    </>
  );
}

export default Home;
