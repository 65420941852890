import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import meta from "../Image/meta.png";
import walletconnect from "../Svg/walletconnect.svg";
import opensea from "../Image/opensea.png";

import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "./Navbar";
import { useStoreState } from "easy-peasy";
import { get_Minting_Contract } from "./../../Web3/web3";
import { toast } from "react-toastify";
import { BiExit } from "react-icons/bi";
import { NFT_CONTRACT_ADDRESSS } from "../../Web3/Contract";
import duck_image21 from "../Image/duck_image.png";
import { AiOutlineCopy } from "react-icons/ai";
import metaspin from "../Image/metaspin.png";
import coinstore from "../Image/coinstore.png";
import bitget from "../Image/bitget.png";
import game_station from "../Image/gamestation.png";
import stealth from "../Image/stealth.png";
import drop from "../Image/drop.png";
import { Link } from "react-router-dom";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

const Error = (msg) =>
  toast.error(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

function Home({ Dissconnect, Metamask, WalletC }) {
  const user = useStoreState((state) => state.user);
  const [show2, setShow2] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [nftContract, setNFTContract] = useState();
  const [numberOfNft, setNumberOfNft] = useState(1);
  const [nftBalance, setNFTBalance] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);

  useEffect(() => {
    const init = async () => {
      const nft = await get_Minting_Contract();
      setNFTContract(nft);
      const balance = await nft.methods.balanceOf(user).call();
      setNFTBalance(Number(balance));

      const supply = await nft.methods.maxSupply().call();
      const minted = await nft.methods.totalSupply().call();
      setTotalSupply(minted);
      setMaxSupply(supply);
    };
    init();
  }, [user, open]);

  const sliceaddress = (add) => {
    if (typeof add === "string") {
      const first = add.slice(0, 5);
      const seconds = add.slice(36);
      return first + "..." + seconds;
    }
  };

  const nftMiniting = async () => {
    try {
      setOpen(true);
      const price = await nftContract.methods.mintPrice().call();
      const data = await nftContract.methods
        .mint(numberOfNft)
        .send({ from: user, value: Number(price) * Number(numberOfNft) });
      if (data.status) {
        notify("Minted Success");
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    notify("copied");
  };

  return (
    <>
    <div className="container-kws">
      <section>
        <Navbar
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
        />
        <div className="ps-r">
          <div className="t-a-c ps-r f-s-7 txt_shadow l-h-1 t_g m-t-1  f-w-600">
            DUCK RACE DERBY <img src={drop} className="ps-a w-50 l-25" />
          </div>
        </div>
        <div className="row m-t-_2">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-3  m-q-b-d-n">
            {" "}
            <div className="t-a-c">
              <div className="t_g f-s-2 f-w-600">PARTNERED WITH</div>
              <div className="">
                <a href="https://metaspins.com/" target="_blank">
                  <img src={metaspin} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://www.gamestation.io/" target="_blank">
                  <img src={game_station} className="w-5 m-y-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6  ">
            {" "}
            <div className="">
              <img src={duck_image21} className="z-i-9 d-b m-a" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-3  m-q-a-d-n">
            {" "}
            <div className="t-a-c">
              <div className="t_g f-s-2 f-w-600">PARTNERED WITH</div>
              <div className="">
                <a href="https://metaspins.com/" target="_blank">
                  <img src={metaspin} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://www.gamestation.io/" target="_blank">
                  <img src={game_station} className="w-5 m-y-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-3">
            {" "}
            <div className="t-a-c">
              <div className="t_g f-s-2 f-w-600 m-q-b-d-n">PARTNERED WITH</div>
              <div className="">
                <a href="https://www.coinstore.com/#/" target="_blank">
                  <img src={coinstore} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://stealthex.io/" target="_blank">
                  <img src={stealth} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://www.bitget.com/" target="_blank">
                  <img src={bitget} className="w-9 m-y-1" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="m-q-d-f-900 a-i-c j-c-c ">
          <div className="m-q-m-r-1">
            {" "}
            <a
              href="https://opensea.io/collection/duck-race-derby-pond-2"
              target="_blank"
            >
              <button className="b-c-t b-5 f-w-800 f-s-1_25 p-x-0_5 h-4 d-i-b  b-r-10 b_y_c bg_b_c  m-a d-b  m-b-1">
                {" "}
                <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                  <img src={opensea} alt="" className="w-1_5 h-c-v-c" />{" "}
                </div>
              </button>
            </a>
          </div>
          <div className="m-q-m-r-3">
            <div className="">
              <a href="/MintPond">
                <button className="b-c-t b-5 f-w-800 f-s-2_5 w-15 h-7  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                  {" "}
                  <span className="t_g f-w-600"> Mint a pond</span>
                </button>
              </a>
            </div>
            <div className="">
              {" "}
              <a
                href="https://docs.google.com/document/d/1WceVGSuvrTZe4IU9uqxK7f95gA59cdGiEX1wWaOM1Wo/edit?usp=drivesdk"
                target="_blank"
              >
                <button className="b-c-t b-5 f-w-800 f-s-1_25  w-15   h-4 d-i-b  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b  ">
                  {" "}
                  <span className="t_g f-w-600"> WHITEPAPER </span>
                </button>{" "}
              </a>
            </div>
          </div>
          <div className="m-q-m-r-3">
            {" "}
            <a href="/ContactForm">
              <button className="b-c-t b-5 f-w-800 f-s-2_5 w-11 h-5   b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600">SIGNUP</span>
              </button>
            </a>
          </div>
          <div className="m-q-m-r-1">
            <div className="">
              <Link to="/MintRacingDuck">
                <button className="b-c-t b-5 f-w-800 f-s-2 w-15 h-7 l-h-1  b-r-10 b_y_c bg_b_c m-b-1    m-a d-b ">
                  {" "}
                  <span className="t_g f-w-600">
                    {" "}
 mint racing ducks
                  </span>
                </button>
              </Link>
            </div>
            <div className="">
              {" "}
              <a
                href="https://drive.google.com/file/d/1Ig7rl49LowS_qbbdOUXHaT_WPLrSctf3/view"
                target="_blank"
              >
                <button className="b-c-t b-5 f-w-800 f-s-1_25  w-15   h-4 d-i-b  b-r-10 b_y_c bg_b_c  m-a d-b  m-b-1">
                  {" "}
                  <span className="t_g f-w-600"> WHITEPAPER </span>
                </button>{" "}
              </a>
            </div>
          </div>
          <div className="">
            {" "}
            <a
              href="https://opensea.io/collection/duck-race-derby-racing-duck"
              target="_blank"
            >
              <button className="b-c-t b-5 f-w-800 f-s-1_25 p-x-0_5 h-4 d-i-b  b-r-10 b_y_c bg_b_c  m-a d-b  m-b-1">
                {" "}
                <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                  <img src={opensea} alt="" className="w-1_5 h-c-v-c" />{" "}
                </div>
              </button>
            </a>
          </div>
        </div>

        <Modal show={show2} onHide={handleClose2} className="">
          <div className="">
            <Modal.Header closeButton>
              <Modal.Title className="t-a-l  c-i">
                Please Connect Your Wallet
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
                <img
                  src={meta}
                  alt=""
                  className="w-5"
                  onClick={() => {
                    Metamask();
                    handleClose2();
                  }}
                />
                <img
                  src={walletconnect}
                  alt=""
                  className="w-5"
                  onClick={() => {
                    WalletC();
                    handleClose2();
                  }}
                />
              </div>
            </Modal.Body>
          </div>
        </Modal>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </section>
      </div>
    </>
  );
}

export default Home;
