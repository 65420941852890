import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import meta from "../Image/meta.png";
import walletconnect from "../Svg/walletconnect.svg";
import opensea from "../Image/opensea.png";

import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "./Navbar";
import { useStoreState } from "easy-peasy";
import { get_Minting_Contract } from "./../../Web3/web3";
import { toast } from "react-toastify";
import { BiExit, BiFontSize } from "react-icons/bi";
import { NFT_CONTRACT_ADDRESSS } from "../../Web3/Contract";
import duck_image21 from "../Image/duck_image.png";
import { AiOutlineCopy } from "react-icons/ai";
import metaspin from "../Image/metaspin.png";
import coinstore from "../Image/coinstore.png";
import bitget from "../Image/bitget.png";
import game_station from "../Image/gamestation.png";
import stealth from "../Image/stealth.png";
import Mint_pond_navbar from "./Mint_pond_navbar";
import sticker from "../Image/auto.png";
import drop from "../Image/drop.png";
import { Link } from "react-router-dom";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

const Error = (msg) =>
  toast.error(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

function Home({ Dissconnect, Metamask, WalletC }) {
  const user = useStoreState((state) => state.user);
  const [show2, setShow2] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [nftContract, setNFTContract] = useState();
  const [numberOfNft, setNumberOfNft] = useState(1);
  const [nftBalance, setNFTBalance] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);

  useEffect(() => {
    const init = async () => {
      const nft = await get_Minting_Contract();
      setNFTContract(nft);
     

      const supply = await nft.methods.maxSupply().call();
      const minted = await nft.methods.totalSupply().call();
      setTotalSupply(minted);
      setMaxSupply(supply);
      const balance = await nft.methods.balanceOf(user).call();
      setNFTBalance(Number(balance));
    };
    init();
  }, [user, open]);

  const sliceaddress = (add) => {
    if (typeof add === "string") {
      const first = add.slice(0, 5);
      const seconds = add.slice(36);
      return first + "..." + seconds;
    }
  };

  const nftMiniting = async () => {
    try {
      setOpen(true);
      const price = await nftContract.methods.mintPrice().call();
      const data = await nftContract.methods
        .mint(numberOfNft)
        .send({ from: user, value: Number(price) * Number(numberOfNft) });
      if (data.status) {
        notify("Minted Success");
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    notify("copied");
  };

  return (
    <>
     <div className="container-kws_contact">
      <section>
        <Mint_pond_navbar
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
        />
        <div className="ps-r">
          <div className="t-a-c ps-r f-s-7 txt_shadow l-h-1 t_g m-t-1  f-w-600">
            DUCK RACE DERBY <img src={drop} className="ps-a w-50 l-25" />
          </div>
        </div>
        <div className="row m-t-_2">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-3  ">
            {" "}
            <div className="t-a-c">
              <img src={sticker} className="" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6  ">
            {" "}
            <div className="">
              <img src={duck_image21} className="z-i-9 d-b m-a" />
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-3 m-q-b-d-n">
            {" "}
            <div className="t-a-c">
              <div className="t_g f-s-2 f-w-600 ">PARTNERED WITH</div>
              <div className="">
                <a href="https://metaspins.com/" target="_blank">
                  <img src={metaspin} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://www.gamestation.io/" target="_blank">
                  <img src={game_station} className="w-5 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://www.coinstore.com/#/" target="_blank">
                  <img src={coinstore} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://stealthex.io/" target="_blank">
                  <img src={stealth} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://www.bitget.com/" target="_blank">
                  <img src={bitget} className="w-9 m-y-1" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="  t-a-c m-b-1 f-s-1_5 ">
          Contract address : {sliceaddress(NFT_CONTRACT_ADDRESSS)}{" "}
          <AiOutlineCopy
            className=" c-p"
            onClick={() => copyToClipboard(NFT_CONTRACT_ADDRESSS)}
          />
        </div>
        <div className="">
          {" "}
          <button
            className="b-c-t b-5 f-w-800 f-s-2 l-h-1 p-y-0_5 w-21   b-r-10 b_y_c bg_b_c m-b-1  m-a d-b"
            // onClick={handleShow2}
          >
            {" "}
            <span className="t_g f-w-600">Mint Price 0.23 ETH </span>
        
            {/* <br />
            <span className="t_g f-w-400 f-s-1_25">
              PUBLIC SALE 0.075{" "}
            </span>{" "} */}
          </button>
        </div>
        {/* <div className="m-b-1">
          {" "}
          <button className="b-c-t b-5 f-w-800 f-s-2_5 w-21   b-r-10 b_y_c bg_b_c m-b-0_5  m-a d-b">
            {" "}
            <span className="t_g f-w-600"> Price 0.23</span>
          </button>
        </div> */}
        <div className="m-q-d-f-900 a-i-c j-c-c m-b-1">
          <div className="m-q-m-r-3">
            <div>
              {user ? (
                ""
              ) : (
                <button
                  className="b-c-t b-5 f-w-800 p-y-0_5 f-s-2_5 w-21   b-r-10 b_y_c bg_b_c m-b-1  m-a d-b"
                  onClick={() => {
                    if (user) {
                      Dissconnect();
                      return;
                    }
                    handleShow2();
                  }}
                >
                  {" "}
                  <span className="t_g f-w-600">
                    {" "}
                    {user ? sliceaddress(user) : "CONNECT WALLET"}{" "}
                  </span>
                </button>
              )}
              {user ? (
                <button className="b-c-t b-5 f-w-800 f-s-2_5 w-21 m-q-h-9   b-r-10 b_y_c bg_b_c m-b-0_5  m-a d-b">
                  {" "}
                  <div className="m-y-0_5">
                    <Form.Select
                      aria-label="Default select example"
                      className="b-c-t w-9 m-a b-r-10 b-5 b_y_c"
                      onChange={(e) => setNumberOfNft(e.target.value)}
                      defaultValue="1"
                    >
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                      <option value="4">Four</option>
                      <option value="5">Five</option>
                      <option value="6">six</option>
                      <option value="7">seven</option>
                      <option value="8">eight</option>
                      <option value="9">nine</option>
                      <option value="10">ten</option>
                    </Form.Select>
                  </div>
                  <div className="" onClick={() => nftMiniting()}>
                    <button className="b-c-t b-5 f-w-800 f-s-1_25 p-x-2 p-y-0_5 b-r-10 b_y_c bg_b_c m-b-0_5  m-a d-b">
                      {" "}
                      <span className="t_g f-w-600"> MINT </span>
                    </button>{" "}
                  </div>
                </button>
              ) : (
                ""
              )}{" "}
            </div>
          </div>
          <div className="m-q-m-r-3">
            <div className="t-a-c">
              <a
                href="https://opensea.io/collection/duck-race-derby-pond-2"
                target="_blank"
              >
                <button className="b-c-t b-5 f-w-800 m-b-1 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                    {" "}
                    <img src={opensea} alt="" className="w-1_5 h-c-v-c" />{" "}
                  </div>
                </button>
              </a>
            </div>
          </div>
          <div className="">
            {" "}
            <button className="b-c-t b-5 f-w-800 p-y-0_5 f-s-2_5 w-21 m-q-h-9 p-y-0_5 b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
              {" "}
              <div className="t_g f-w-600 l-h-1">
                {" "}
                Total Minted <br />
                {`${totalSupply}/${maxSupply}`}
                <p className="t-a-c m-y-1 f-s-0_8 c-w t-t-l">(Connect Wallet to view)</p>
              </div>
            </button>
          </div>
        </div>

        <div className="m-q-d-f-900 a-i-c j-c-c">
          <div className="m-q-m-r-1">
            <a href="/" target="_blank">
              <button className="b-c-t b-5 f-w-800  f-s-1_5 w-19  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> www.duckracederby.xyz</span>
              </button>
            </a>
          </div>
          <div className="m-q-m-r-1">
            {" "}
            <a
              href="https://docs.google.com/document/d/1WceVGSuvrTZe4IU9uqxK7f95gA59cdGiEX1wWaOM1Wo/edit?usp=drivesdk"
              target="_blank"
            >
              <button className="b-c-t b-5 f-w-800  f-s-1_5 w-19  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> whitepaper</span>
              </button>
            </a>
          </div>
          <div className="m-q-m-r-1">
            <a href="https://discord.gg/3FSFVd3pHE" target="_blank">
              <button className="b-c-t b-5 f-w-800  f-s-1_5 w-19  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> leaderboard</span>
              </button>
            </a>
          </div>
        </div>

        <div className="t-a-c m-q-a-d-n m-t-2">
          <div className="t_g f-s-2 f-w-600">PARTNERED WITH</div>

          <div className="">
            <a href="https://metaspins.com/" target="_blank">
              <img src={metaspin} className="w-11 m-y-1" alt="" />
            </a>
          </div>
          <div className="">
            <a href="https://www.gamestation.io/" target="_blank">
              <img src={game_station} className="w-5 m-y-1" alt="" />
            </a>
          </div>
          <div className="">
            <a href="https://www.coinstore.com/#/" target="_blank">
              <img src={coinstore} className="w-11 m-y-1" />
            </a>
          </div>
          <div className="">
            <a href="https://stealthex.io/" target="_blank">
              <img src={stealth} className="w-11 m-y-1" />
            </a>
          </div>
          <div className="">
            <a href="https://www.bitget.com/" target="_blank">
              <img src={bitget} className="w-9 m-y-1" />
            </a>
          </div>
        </div>

        {/* <div className=" t-a-c m-b-0_5 f-s-1_25 t_g">
          total minted : {`${totalSupply}/${maxSupply}`}
        </div>
        <div className="t-a-c m-b-0_5 f-s-1_25 t_g ">
          Your Balance : {nftBalance}
        </div> */}
        {/* {user ? (
          <div className="t-a-c m-b-0_5 f-s-1_25 t_g ">
            Wallet Address :{" "}
            <span onClick={() => Dissconnect()} className="c-p">
              {" "}
              {sliceaddress(user)} &nbsp; <BiExit />
            </span>
          </div>
        ) : (
          ""
        )} */}
        {/* <div className="row m-b-4">
          <div className="col-12 col-sm-6 col-md-4">
            {" "}
            <div>
              {user ? (
                ""
              ) : (
                <button
                  className="b-c-t b-5 f-w-800 f-s-2_5 w-21 h-9  b-r-10 b_y_c bg_b_c m-b-0_5  m-a d-b"
                  onClick={() => {
                    if (user) {
                      Dissconnect();
                      return;
                    }
                    handleShow2();
                  }}
                >
                  {" "}
                  <span className="t_g f-w-600">
                    {" "}
                    {user ? sliceaddress(user) : "CONNECT WALLET"}{" "}
                  </span>
                </button>
              )}
              {user ? (
                <button className="b-c-t b-5 f-w-800 f-s-2_5 w-21 h-9  b-r-10 b_y_c bg_b_c m-b-0_5  m-a d-b">
                  {" "}
                  <div className="m-y-0_5">
                    <Form.Select
                      aria-label="Default select example"
                      className="b-c-t w-9 m-a b-r-10 b-5 b_y_c"
                      onChange={(e) => setNumberOfNft(e.target.value)}
                    >
                      <option value="1">One</option>
                      <option value="2">Two</option>
                    </Form.Select>
                  </div>
                  <div className="" onClick={() => nftMiniting()}>
                    <button className="b-c-t b-5 f-w-800 f-s-1_25 p-x-2 p-y-0_5 b-r-10 b_y_c bg_b_c m-b-0_5  m-a d-b">
                      {" "}
                      <span className="t_g f-w-600"> MINT </span>
                    </button>{" "}
                  </div>
                </button>
              ) : (
                ""
              )}{" "}
            </div>
            <div className="m-q-d-f-900  j-c-c">
              <div className="">
                {" "}
                <a
                  href="https://docs.google.com/document/d/17zAWegXzlqq5ghNjWwW8LBVQIfyQ66pI3qyrht1Dnb4/edit"
                  target="_blank"
                >
                  <button className="b-c-t b-5 f-w-800 f-s-1_25 w-11 m-r-1 h-4 d-i-b  b-r-10 b_y_c bg_b_c  m-a d-b  m-b-1">
                    {" "}
                    <span className="t_g f-w-600"> WHITEPAPER </span>
                  </button>{" "}
                </a>
              </div>
              <div className="">
                {" "}
                <a
                  href="https://opensea.io/collection/duck-race-derby-pond"
                  target="_blank"
                >
                  <button className="b-c-t b-5 f-w-800 f-s-1_25 p-x-0_5 h-4 d-i-b  b-r-10 b_y_c bg_b_c  m-a d-b  m-b-1">
                    {" "}
                    <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                      <img src={opensea} alt="" className="w-1_5 h-c-v-c" />{" "}
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="col-12 col-sm-6 col-md-4 ">
            <div>
              <a href="/ComingSoon">
                <button className="b-c-t b-5 f-w-800 f-s-2_5 w-21 m-q-m-t-4  b-r-10 b_y_c bg_b_c m-b-0_5  m-a d-b">
                  {" "}
                  <span className="t_g f-w-600">SIGNUP FOR WHITELIST</span>
                </button>
              </a>
              <div className="t_g f-s-2 m-t-1 t-a-c f-w-600 m-q-b-d-n">
                {" "}
                PARTNERED WITH{" "}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            {" "}
            <div className="">
              <button
                className={`b-c-t b-5 f-w-800 f-s-2_5 w-21 h-9  b-r-10 b_y_c bg_b_c m-b-0_5 m-a d-b `}
              >
                {" "}
                <span className={`t_g f-w-600 `}>
                  {" "}
                  WHITELIST MINT <span className="c-w">RACING DUCKS</span>{" "}
                </span>
              </button>{" "}
            </div>
            <div className="d-f  j-c-c">
              <div className="">
                {" "}
                <a
                  href="https://drive.google.com/file/d/1Ig7rl49LowS_qbbdOUXHaT_WPLrSctf3/view?usp=sharing"
                  target="_blank"
                >
                  <button className="b-c-t b-5 f-w-800 f-s-1_25 w-11 m-r-1 h-4 d-i-b  b-r-10 b_y_c bg_b_c  m-a d-b  m-b-1">
                    {" "}
                    <span className="t_g f-w-600"> WHITEPAPER </span>
                  </button>{" "}
                </a>
              </div>
              <div className="">
                {" "}
                <a
                  href="https://opensea.io/collection/duck-race-derby-racing-duck"
                  target="_blank"
                >
                  <button className="b-c-t b-5 f-w-800 f-s-1_25 p-x-0_5 h-4 d-i-b  b-r-10 b_y_c bg_b_c  m-a d-b  m-b-1">
                    {" "}
                    <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                      <img src={opensea} alt="" className="w-1_5 h-c-v-c" />{" "}
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="t_g f-s-2 m-t-1 t-a-c f-w-600 m-q-a-d-n">
          {" "}
          PARTNERED WITH{" "}
        </div>
        <div className="d-f a-i-c j-c-c flex-wrap">
          <div className="m-r-3">
            <a href="https://metaspins.com/" target="_blank">
              <img src={metaspin} className="w-11 m-y-1" />
            </a>
          </div>
          <div className="m-r-3">
            <a href="https://www.gamestation.io/" target="_blank">
              <img src={game_station} className="w-3 m-y-1" />
            </a>
          </div>
          <div className="m-r-3">
            <a href="https://www.coinstore.com/#/" target="_blank">
              <img src={coinstore} className="w-11 m-y-1" />
            </a>
          </div>
          <div className="m-r-3">
            <a href="https://stealthex.io/" target="_blank">
              <img src={stealth} className="w-11 m-y-1" />
            </a>
          </div>
          <div className="">
            <a href="https://www.bitget.com/" target="_blank">
              <img src={bitget} className="w-9 m-y-1" />
            </a>
          </div>
        </div> */}
        <Modal show={show2} onHide={handleClose2} className="">
          <div className="">
            <Modal.Header closeButton>
              <Modal.Title className="t-a-l  c-i">
                Please Connect Your Wallet
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
                <img
                  src={meta}
                  alt=""
                  className="w-5"
                  onClick={() => {
                    Metamask();
                    handleClose2();
                  }}
                />
                <img
                  src={walletconnect}
                  alt=""
                  className="w-5"
                  onClick={() => {
                    WalletC();
                    handleClose2();
                  }}
                />
              </div>
            </Modal.Body>
          </div>
        </Modal>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </section>
      </div>
    </>
  );
}

export default Home;
