import React from "react";
import { AiOutlineCopyrightCircle } from "react-icons/ai";

function Footer() {
  return (
    <div className=" m-t-5  f-s-1_25">
      <hr className="p-b-0 m-b-0 c-w" />
      <div className="t-a-c m-y-2 f-s-0_8 c-w t-t-l">Please be advised, when investing in anything that your investment may go down as well as up.</div>
      <div className="m-b-2 t_g">
        <AiOutlineCopyrightCircle className="f_y_c" /> 2023 Designed by{" "}
        <a href="https://kesaviwebsolutions.com/" target="_blank">
          {" "}
          Kesavi Web Solution{" "}
        </a>
      </div>
    </div>
  );
}

export default Footer;
