import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import meta from "../Image/meta.png";
import walletconnect from "../Svg/walletconnect.svg";

import Dropdown from "react-bootstrap/Dropdown";
import Minting_Navbar from "./Minting_navbar";
import { get_Minting_DUCK_Contract } from "../../Web3/web3";
import { useStoreState } from "easy-peasy";
import metaspin from "../Image/metaspin.png";
import coinstore from "../Image/coinstore.png";
import bitget from "../Image/bitget.png";
import game_station from "../Image/gamestation.png";
import stealth from "../Image/stealth.png";
import { DUCK_NFT_ADDRESSS } from "../../Web3/Contract";
// import duck_image from "../Image/duck_image";
import duck_image21 from "../Image/duck_image.png";
import { toast } from "react-toastify";
import opensea from "../Image/opensea.png";
import drop from "../Image/drop.png";
import { AiOutlineCopy } from "react-icons/ai";

const notify = (msg) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

const Error = (msg) =>
  toast.error(msg, {
    position: toast.POSITION.TOP_LEFT,
  });

function Home({ Dissconnect, Metamask, WalletC }) {
  const user = useStoreState((state) => state.user);
  const [nftContract, setNFTContract] = useState();
  const [numberOfNft, setNumberOfNft] = useState(1);
  const [nftBalance, setNFTBalance] = useState(0);
  const [open, setOpen] = useState(false);
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxperWallet, setMaxPerWAllet] = useState([]);

  useEffect(() => {
    const init = async () => {
      const nft = await get_Minting_DUCK_Contract();
      console.log("contract", nft)
      setNFTContract(nft);
      
      const supply = await nft.methods.maxSupply().call();
      const minted = await nft.methods.totalSupply().call();
      const mat = await nft.methods.maxPerTx().call();
      const tx = [];
      for (let i = 0; i < mat; i++) {
        tx.push(i + 1);
      }
      
      setMaxPerWAllet(tx);
      setTotalSupply(minted);
      setMaxSupply(supply);
      const balance = await nft.methods.balanceOf(user).call();
      setNFTBalance(Number(balance));
    };
    init();
  }, [user, open]);

  const nftMiniting = async () => {
    try {
      setOpen(true);
      const price = await nftContract.methods.mintPrice().call();
      const data = await nftContract.methods
        .mint(numberOfNft)
        .send({ from: user, value: Number(price) * Number(numberOfNft) });
      if (data.status) {
        notify("Minted Success");
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const sliceaddress = (add) => {
    if (typeof add === "string") {
      const first = add.slice(0, 5);
      const seconds = add.slice(36);
      return first + "..." + seconds;
    }
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    notify("copied");
  };

  return (
    <>
         <div className="container-kws_contact">
      <section>
        <Minting_Navbar
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
        />
        <div className="ps-r">
          <div className="t-a-c ps-r f-s-7 txt_shadow l-h-1 t_g m-t-1  f-w-600">
            DUCK RACE DERBY <img src={drop} className="ps-a w-50 l-25" />
          </div>
        </div>
        <div className="row m-t-_2">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-3  m-q-b-d-n">
            {" "}
            <div className="t-a-c">
              <div className="t_g f-s-2 f-w-600">PARTNERED WITH</div>
              <div className="">
                <a href="https://metaspins.com/" target="_blank">
                  <img src={metaspin} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://www.gamestation.io/" target="_blank">
                  <img src={game_station} className="w-5 m-y-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-6  ">
            {" "}
            <div className="">
              <img src={duck_image21} className="z-i-9 d-b m-a" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-3  m-q-a-d-n">
            {" "}
            <div className="t-a-c">
              <div className="t_g f-s-2 f-w-600">PARTNERED WITH</div>
              <div className="">
                <a href="https://metaspins.com/" target="_blank">
                  <img src={metaspin} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://www.gamestation.io/" target="_blank">
                  <img src={game_station} className="w-5 m-y-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-3">
            {" "}
            <div className="t-a-c">
              <div className="t_g f-s-2 f-w-600 m-q-b-d-n">PARTNERED WITH</div>
              <div className="">
                <a href="https://www.coinstore.com/#/" target="_blank">
                  <img src={coinstore} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://stealthex.io/" target="_blank">
                  <img src={stealth} className="w-11 m-y-1" />
                </a>
              </div>
              <div className="">
                <a href="https://www.bitget.com/" target="_blank">
                  <img src={bitget} className="w-9 m-y-1" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="t-a-c m-t-3  m-b-0_5 f-s-1_25 t_g">
          {" "}
          Your Balance : {nftBalance}
        </div> */}
        <div className="t-a-c m-b-0_5 f-s-1_5 ">
          CONTRACT ADDRESS : {sliceaddress(DUCK_NFT_ADDRESSS)}{" "}
          <AiOutlineCopy
            className=" c-p"
            onClick={() => copyToClipboard(DUCK_NFT_ADDRESSS)}
          />
        </div>

        <div className="">
          {" "}
          <button
            className="b-c-t b-5 f-w-800 f-s-2 l-h-1 p-y-0_5 w-21   b-r-10 b_y_c bg_b_c m-b-1  m-a d-b"
            // onClick={handleShow2}
          >
            {" "}
            <span className="t_g f-w-600">Duck Price 0.02 ETH </span>
            <br />
            <span className="t_g f-w-400">
              MAX 10 PER TRANSACTION{" "}
            </span>
            {/* <br />
            <span className="t_g f-w-400 f-s-1_25">
              PUBLIC SALE 0.075{" "}
            </span>{" "} */}
          </button>
        </div>

        <div className="m-q-d-f-900 a-i-c j-c-c">
          <div className="m-q-m-r-3">
            <div className="">
              <div>
                {!user ? (
                  <button
                    className="b-c-t b-5 f-w-800 f-s-2_5 w-21 h-9  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b"
                    onClick={handleShow2}
                  >
                    {" "}
                    <span className="t_g f-w-600 "> connect wallet</span>
                  </button>
                ) : (
                  <button className="b-c-t b-5 f-w-800 f-s-2_5 w-21 m-q-h-9  b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                    {" "}
                    <div className="m-y-0_5">
                      <Form.Select
                        aria-label="Default select example"
                        className="b-c-t w-9 m-a b-r-10 b-5 b_y_c "
                        onChange={(e) => setNumberOfNft(e.target.value)}
                      >
                        {maxperWallet &&
                          maxperWallet.map((res) => {
                            return <option value={`${res}`}>{res}</option>;
                          })}
                      </Form.Select>
                    </div>
                    <div className="">
                      <button
                        onClick={() => nftMiniting()}
                        className="b-c-t b-5 f-w-800 f-s-1_25 p-x-2 p-y-0_5 b-r-10 b_y_c bg_b_c m-b-1  m-a d-b"
                      >
                        {" "}
                        <span className="t_g f-w-600"> MINT </span>
                      </button>{" "}
                    </div>
                  </button>
                )}{" "}
              </div>
            </div>
            <div className="">
              <a href="/" target="_blank">
                <button className="b-c-t b-5 f-w-800 f-s-1_5 w-21   b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                  {" "}
                  <span className="t_g f-w-600"> www.duckracederby.xyz</span>
                </button>
              </a>
            </div>
          </div>
          <div className="m-q-m-r-3 t-a-c m-b-1">
            {" "}
            <a
              href="https://opensea.io/collection/duck-race-derby-racing-duck"
              target="_blank"
            >
              <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                {" "}
                <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                  {" "}
                  <img src={opensea} alt="" className="w-1_5 h-c-v-c" />{" "}
                </div>
              </button>
            </a>
          </div>
          <div className="">
            <div className="">
              <button className="b-c-t b-5 f-w-800 p-y-0_5 m-q-h-9 f-s-2_5 w-21 p-y-0_5 b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                {" "}
                <div className="t_g f-w-600 l-h-1">
                  {" "}
                  Total Minted <br />
                  {`${totalSupply}/${maxSupply}`}
                  <p className="t-a-c m-y-1 f-s-0_8 c-w t-t-l">(Connect Wallet to view)</p>
                </div>
              </button>
            </div>
            <div className="">
              <a
                href="https://drive.google.com/file/d/1Ig7rl49LowS_qbbdOUXHaT_WPLrSctf3/view"
                target="_blank"
              >
                <button className="b-c-t b-5 f-w-800 f-s-1_5 w-21   b-r-10 b_y_c bg_b_c m-b-1  m-a d-b">
                  {" "}
                  <span className="t_g f-w-600"> whitepaper</span>
                </button>
              </a>
            </div>
          </div>
        </div>

        {/* <div className="row ">
          <div className="col-12 col-sm-6 col-md-6">
            {" "}
          
          </div>
          <div className="col-12 col-sm-6 col-md-6">
            {" "}
            <button className="b-c-t b-5 f-w-800 f-s-1_5 w-21   b-r-10 b_y_c h-9 bg_b_c m-b-1 m-a d-b">
              {" "}
              <span className="t_g f-w-600">
                {" "}
                TOTAL MINTED {`${totalSupply}/${maxSupply}`}
              </span>
            </button>{" "}
            <a
              href="https://drive.google.com/file/d/1Ig7rl49LowS_qbbdOUXHaT_WPLrSctf3/view?usp=sharing"
              target="_blank"
            >
              <button className="b-c-t b-5 f-w-800 f-s-1_25 w-21 m-b-1  b-r-10 b_y_c bg_b_c  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> WHITEPAPER </span>
              </button>{" "}
            </a>
          </div>
        </div> */}

        <Modal show={show2} onHide={handleClose2} className="">
          <div className="">
            <Modal.Header closeButton>
              <Modal.Title className=" c-i">
                Please Connect Your Wallet
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
                <img
                  src={meta}
                  alt=""
                  className="w-5"
                  onClick={() => {
                    Metamask();
                    handleClose2();
                  }}
                />
                <img
                  src={walletconnect}
                  alt=""
                  className="w-5"
                  onClick={() => {
                    WalletC();
                    handleClose2();
                  }}
                />
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </section>
      </div>
    </>
  );
}

export default Home;
