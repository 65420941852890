import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import meta from "../Image/meta.png";
import walletconnect from "../Svg/walletconnect.svg";

import Dropdown from "react-bootstrap/Dropdown";
import Minting_Navbar from "./Minting_navbar";
import { useStoreState } from "easy-peasy";
import axios from "axios";
import { toast } from "react-toastify";
import { get_Minting_Contract } from "../../Web3/web3";
import duck_image21 from "../Image/duck_image.png";
import metaspin from "../Image/metaspin.png";
import coinstore from "../Image/coinstore.png";
import bitget from "../Image/bitget.png";
import drop from "../Image/drop.png";
import game_station from "../Image/gamestation.png";
import stealth from "../Image/stealth.png";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);

function Home() {
  const [show2, setShow2] = useState(false);
  const api_key = useStoreState((state) => state.email_api_key);
  const user = useStoreState((state) => state.user);
  const [email, setEmail] = useState("");
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [open, setOpen] = useState(false);
  const [wallet, setWallet] = useState();
  const [maxperWallet, setMaxPerWAllet] = useState("");
  const [query, setquerys] = useState();

  useEffect(() => {
    const init = async () => {
      const nft = await get_Minting_Contract();
      const mat = await nft.methods.maxPerWallet().call();
      setMaxPerWAllet(mat);
    };
    init();
  }, [user]);

  const Send_Email = async (e) => {
    try {
      setOpen(true);
      axios({
        method: "post",
        url: "https://api.sendinblue.com/v3/smtp/email",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "api-key": `${api_key}`,
        },
        data: {
          sender: {
            name: `${email}`,
            email: `${email}`,
          },
          to: [
            {
              email: `mint@thedrd.xyz`,
              name: ".",
            },
          ],
          subject: "Query",
          htmlContent: ` <html>
          <head>
          <style>
          .f-s-1{
            font-size:1rem;
          }
          .t-a-c{
            text-align:center;
          }
          .m-y-1{
            margin-top:1rem;
            margin-bottom:1rem;
          }
          .w-9{
            width:9rem;
          }
          .f-s-1_25{
            font-size:1.25rem;
          }
          .f-w-600{
            font-weight:600;
          }
          .f-s-1_5{
            font-size:1.5rem;
          }
          .d-i-b{
            display:inline-block;
          }
          .m-a{
            margin:auto;
          }
          .p-y-0_5{
            padding-top:0.5rem;
            padding-bottom:0.5rem;
          }
          .p-x-3{
            padding-left:3rem;
            padding-right:3rem;
          }
          .m-y-3{
            margin-top:3rem;
            margin-bottom:3rem;
          }
          .dark-gray-bg-clr{
            background-color:#E2E2E2;
          }
          .p-t-3{
            padding-top:3rem;
          }
          .p-t-2{
            padding-top:2rem;
          }
          .b-r-1{
            border-radius:1rem;
          }
          </style>
          </head>
          
          <body>
          <div class="t-a-c">
          <div class="t-a-c  m-y-3 ">
          <img src="https://ipfs.io/ipfs/Qmf4EiiY8rNBfYSFd58jz24FcfpFgrcdyxzwbCgbjZvWFG?filename=photo_2023-08-16_15-00-02.jpg" class="w-9 t-a-c" />
          </div>
          <div class="t-a-c f-s-1_25 m-y-3">Email : </div>
          <div class="t-a-c f-s-1 m-y-3">­	
         ${email} </div>
          <div class="t-a-c f-s-1 m-y-3 ">­	
         ${query} </div>
          </div>
      </body>
      </html>`,
        },
      })
        .then((res) => {
          if (res.status && res.status == 201) {
            notify("Email sent");
            setOpen(false);
          }
        })
        .catch((er) => {
          setOpen(false);
        });
    } catch (error) {
      setOpen(false);
    }
  };
  return (
    <>
      <div className="container-kws_contact">
        <section>
          <Minting_Navbar />
          <div className="ps-r">
            <div className="t-a-c ps-r f-s-7 txt_shadow l-h-1 t_g m-t-1  f-w-600">
              Contact <img src={drop} className="ps-a w-50 l-25" />
            </div>
          </div>
          <div className="row m-t-_2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-4   t-a-c m-q-b-d-n">
              <div className="f-s-1_25 ">
                <div className="f-w-600 f-s-2 m-b-2"> Sponsor a race?</div>
                <div className="m-b-2">
                  {" "}
                  Get in contact using the form OR email at{" "}
                  <a href="mailto:contact@thedrd.xyz">contact@thedrd.xyz</a>
                </div>
                <div className="m-b-2">
                  {" "}
                  To discuss the different options and to receive the pitch deck
                </div>

                <div className="m-b-2">
                  You can also download the pitch deck here{" "}
                  <a href="https://shorturl.at/gHIV2">
                    https://shorturl.at/gHIV2
                  </a>
                </div>
              </div>
              </div>{" "}
              <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-4  m-a">
                {" "}
                <div className="">
                  <img src={duck_image21} className=" d-b m-a" />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-4  t-a-c m-q-b-d-n">
                <div className="f-s-1_25 ">
                  <div className="f-s-2 f-w-600 m-b-2">
                    {" "}
                    Want to help and earn?{" "}
                  </div>

                  <div className="m-b-2">
                    You can earn commission on all sponsors you bring to the
                    Duck Race Derby; includes ongoing returns.{" "}
                  </div>
                  <div className="m-b-2">
                    {" "}
                    Different earnings for holders and non-holders{" "}
                  </div>
                  <div className="m-b-2">
                    {" "}
                    Download the pitch deck here Link:{" "}
                    <a href="https://shorturl.at/gHIV2">
                      https://shorturl.at/gHIV2
                    </a>
                  </div>
                  <div className="m-b-2">
                    {" "}
                    Need help on WHY it's good to sponsor? One page explainer
                    here: Link: To be updated!
                  </div>
                </div>
              </div>{" "}
          
          </div>
          <div className="  ">
            <div className="">
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                className="b-c-t b-5 f-w-800 f-s-1_25 pa-0_5  m-b-1 b-r-10 w-20 m-q-w-100 b_y_c bg_b_c  m-a d-b w-34"
                id="m-q-w-34"
                placeholder="Enter email..."
              />
            </div>
            <div className="">
              <textarea
                // type="text"
                value={query}
                onChange={(e) => setquerys(e.target.value)}
                className="b-c-t b-5 f-w-800 f-s-1_25 pa-0_5  m-b-1 b-r-10 w-20 m-q-w-100 b_y_c bg_b_c  m-a d-b w-34"
                id="m-q-w-34"
                placeholder="Enter query..."
              />
            </div>
            <div className="">
              <button
                className="b-c-t b-5 f-w-800 f-s-2 w-21 m-b-2 w-34 z-i-9  m-q-w-100 b-r-10 b_y_c bg_b_c  m-a d-b"
                id="m-q-w-34"
                onClick={() => Send_Email()}
              >
                <span className="t_g f-w-600"> send</span>
              </button>
            </div>
          </div>
          <div className="t_g f-s-2 f-w-600 t-a-c ">PARTNERED WITH</div>
          <div className="d-f a-i-c j-c-c flex-wrap m-b-3 ">
            <div className="m-r-3">
              <a href="https://metaspins.com/" target="_blank">
                <img src={metaspin} className="w-11 m-y-1" />
              </a>
            </div>
            <div className="m-r-3">
              <a href="https://www.gamestation.io/" target="_blank">
                <img src={game_station} className="w-3 m-y-1" />
              </a>
            </div>
            <div className="m-r-3">
              <a href="https://www.coinstore.com/#/" target="_blank">
                <img src={coinstore} className="w-11 m-y-1" />
              </a>
            </div>
            <div className="m-r-3">
              <a href="https://stealthex.io/" target="_blank">
                <img src={stealth} className="w-11 m-y-1" />
              </a>
            </div>
            <div className="">
              <a href="https://www.bitget.com/" target="_blank">
                <img src={bitget} className="w-9 m-y-1" />
              </a>
            </div>
          </div>
          <div className="f-s-1_25 t-a-c m-q-a-d-n">
                <div className="f-w-600 f-s-2 m-b-2"> Sponsor a race?</div>
                <div className="m-b-2">
                  {" "}
                  Get in contact using the form OR email at{" "}
                  <a href="mailto:contact@thedrd.xyz">contact@thedrd.xyz</a>
                </div>
                <div className="m-b-2">
                  {" "}
                  To discuss the different options and to receive the pitch deck
                </div>

                <div className="m-b-2">
                  You can also download the pitch deck here{" "}
                  <a href="https://shorturl.at/gHIV2">
                    https://shorturl.at/gHIV2
                  </a>
                </div>
              </div>
              
              <div className="f-s-1_25 t-a-c m-t-3 m-q-a-d-n">
                  <div className="f-s-2 f-w-600 m-b-2">
                    {" "}
                    Want to help and earn?{" "}
                  </div>

                  <div className="m-b-2">
                    You can earn commission on all sponsors you bring to the
                    Duck Race Derby; includes ongoing returns.{" "}
                  </div>
                  <div className="m-b-2">
                    {" "}
                    Different earnings for holders and non-holders{" "}
                  </div>
                  <div className="m-b-2">
                    {" "}
                    Download the pitch deck here Link:{" "}
                    <a href="https://shorturl.at/gHIV2">
                      https://shorturl.at/gHIV2
                    </a>
                  </div>
                  <div className="m-b-2">
                    {" "}
                    Need help on WHY it's good to sponsor? One page explainer
                    here: Link: To be updated!
                  </div>
                </div>
          {/* <div className="m-q-d-f j-c-s-b  ">
          <div className="t-a-c">
            <div className="t_g">PARTNERED WITH</div>
            <div className="">Metaspins</div>
          </div>
          <div className="t-a-c">
            <div className="t_g m-q-b-d-n">PARTNERED WITH</div>
            <div className="">Metaspins</div>
          </div>
        </div> */}
          {/* <div className="t-a-c m-b-2">CONTRACT ADDRESS : 0x423...23rd3</div> */}

          {/* <div className="row m-t-3">
          <div className="col-12 col-sm-4">
            <input
              type="text"
              onChange={(e) => setWallet(e.target.value)}
              className="b-c-t b-5 f-w-800 f-s-1_25 pa-0_5 w-100 m-b-2 b-r-10 b_y_c bg_b_c  m-a d-b"
              placeholder="Enter wallet address..."
            />
          </div>
          <div className="col-12 col-sm-4">
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              className="b-c-t b-5 f-w-800 f-s-1_25 pa-0_5 w-100 m-b-2 b-r-10 b_y_c bg_b_c  m-a d-b"
              placeholder="Enter email..."
            />
          </div>
          <div className="col-12 col-sm-4">
            {" "}
            <button
              className="b-c-t b-5 f-w-800 f-s-1_25 w-100 m-b-2 pa-0_5 b-r-10 b_y_c bg_b_c  m-a d-b"
              onClick={() => Send_Email()}
            >
              {" "}
              <span className="t_g f-w-600">Signup </span>
            </button>{" "}
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-sm-6 col-md-6">
            {" "}
            <a href="https://duckracederby.xyz" target="_blank">
              <button className="b-c-t b-5 f-w-800 f-s-1_25 w-21 m-b-2 b-r-10 b_y_c bg_b_c  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> WWW.DUCKRACEDERBY.XYZ </span>
              </button>{" "}
            </a>
          </div>
          <div className="col-12 col-sm-6 col-md-6">
            <a
              href="https://drive.google.com/file/d/1Ig7rl49LowS_qbbdOUXHaT_WPLrSctf3/view?usp=sharing"
              target="_blank"
            >
              <button className="b-c-t b-5 f-w-800 f-s-1_25 w-21 m-b-2  b-r-10 b_y_c bg_b_c  m-a d-b">
                {" "}
                <span className="t_g f-w-600"> WHITEPAPER </span>
              </button>{" "}
            </a>
          </div>
          <Modal show={show2} onHide={handleClose2} className="">
            <div className="">
              <Modal.Header closeButton>
                <Modal.Title className="t-a-l m-a c-i">
                  Please Connect Your Wallet
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
                  <img src={meta} alt="" className="w-5" />
                  <img src={walletconnect} alt="" className="w-5" />
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div> */}
        </section>
      </div>
    </>
  );
}

export default Home;
