import React, { useEffect, useLayoutEffect, useState } from "react";

import { ImCross } from "react-icons/im";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillYoutube, AiOutlineMenu, AiOutlineTwitter } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import meta from "../Image/meta.png";
import opensea from "../Image/opensea.png";
import walletconnect from "../Svg/walletconnect.svg";
import twitter_new from "../Image/twitter_new.png";
import link_tree from "../Image/link_tree.png";
import "animate.css";

import logo from "../Image/nfe-logo.png";
import { useStoreState } from "easy-peasy";
import { Link } from "react-router-dom";

function Navbar({ Dissconnect, Metamask, WalletC }) {
  const [show, setShow] = useState(false);
  const user = useStoreState((state) => state.user);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ---------dark---------mode----------------

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  }, []);

  const [theme, setTheme] = useState("light-theme");
  const toggleTheme = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "dark-theme"
    );
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const sliceaddress = (add) => {
    if (typeof add === "string") {
      const first = add.slice(0, 5);
      const seconds = add.slice(36);
      return first + "..." + seconds;
    }
  };

  return (
    <>
      <div className="  navbar12  m-q-d-f a-i-c j-c-s-b  p-y-1 p-x-1">
        <div className="m-q-b-d-n w-9">
          {" "}
          <Link to="/">
            {" "}
            <button className=" b-n f-w-800 f-s-1_5  l-h-1 b-5 pa-0_5 b_y_c  b-r-10 b-c-t bg_b_c  ">
              {" "}
              <span className="t_g f-w-600"> home</span>
            </button>
          </Link>
        </div>

        <div className="p-y-0_5">
          <ul className="l-s-t-n d-f a-i-c j-c-c">
            <li className="m-q-m-r-2 c-p   ">
              <a href="https://www.youtube.com/@duckracederby" target="_blank">
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2 ps-r  b-r-50 m-a">
                    {" "}
                    <AiFillYoutube className="f_w h-c-v-c" />{" "}
                  </div>
                </button>{" "}
              </a>
            </li>
            <li className="m-q-m-r-2 c-p   ">
              <a href="https://twitter.com/DuckRaceDerby" target="_blank">
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2 ps-r   b-r-50 m-a">
                    {" "}
                    <img src={twitter_new} className="f_w h-c-v-c b-r-20 w-1" />
                  </div>
                </button>
              </a>
            </li>
            <li className="m-q-m-r-2 c-p   ">
              <a href="https://discord.com/invite/7QfVfAzgyE" target="_blank">
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                    {" "}
                    <BsDiscord className="f_w h-c-v-c" />
                  </div>
                </button>
              </a>
            </li>
            <li className=" c-p   ">
              <a href="https://linktr.ee/duckracederby" target="_blank">
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25 m-q-m-r-2   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                    {" "}
                    <img
                      src={link_tree}
                      alt=""
                      className="w-1_5 h-c-v-c"
                    />{" "}
                  </div>
                </button>
              </a>
            </li>
            <li className=" c-p   ">
              <a
                href="https://opensea.io/collection/duck-race-derby-racing-duck"
                target="_blank"
              >
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                    {" "}
                    <img src={opensea} alt="" className="w-1_5 h-c-v-c" />{" "}
                  </div>
                </button>
              </a>
            </li>
          </ul>
        </div>
        <div className=" c-p p-y-0_5 ">
          <ul className="l-s-t-n d-f j-c-c a-i-c ">
            <li className=" c-p   ">
              {" "}
              <button
                className="w-s-n-w p-x-1  b-c-t b-5 f-w-800 f-s-1_25  p-y-0_5   b-r-10 b_y_c bg_b_c  m-a d-b "
                onClick={() => {
                  if (user) {
                    Dissconnect();
                    return;
                  }
                  handleShow2();
                }}
              >
                {" "}
                <span className="t_g f-w-600">
                  {" "}
                  {user ? sliceaddress(user) : "CONNECT WALLET"}{" "}
                </span>
              </button>{" "}
            </li>
            {/*   // <li className="m-l-2 c-p   m-q-a-d-n ">
            //   {" "}
            //   <Button onClick={handleShow} className=" b-c-t c-i b-n pa-0">
            //     <AiOutlineMenu />
            //   </Button>
            // </li> */}
          </ul>
        </div>
      </div>

      {/* ---------------mobile-navbar---------------------------------- */}

      <Offcanvas show={show} onHide={handleClose} className="pa-1">
        <Offcanvas.Header>
          <Offcanvas.Title>
            {" "}
            <div className="image-dog1">
              <a href="/" target="_blank">
                <img src={logo} alt="" className="" />
              </a>
            </div>
          </Offcanvas.Title>
          <ImCross onClick={handleClose} color="black" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="l-s-t-n">
            <li className="m-r-1 m-t-1 c-p">dummy</li>
            <li className="m-r-1 m-t-1 c-p">dummy</li>
            <li className="m-r-1 m-t-1 c-p">dummy</li>
            <li className="m-r-1 m-t-1 c-p">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="b-c-t c-i b-n"
                >
                  Dropdown Button
                </Dropdown.Toggle>
                <Dropdown.Menu className="pa-1">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={show2} onHide={handleClose2} className="">
        <div className="">
          <Modal.Header closeButton>
            <Modal.Title className=" c-i">
              Please Connect Your Wallet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
              <img
                src={meta}
                alt=""
                className="w-5"
                onClick={() => {
                  Metamask();
                  handleClose2();
                }}
              />
              <img
                src={walletconnect}
                alt=""
                className="w-5"
                onClick={() => {
                  WalletC();
                  handleClose2();
                }}
              />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Navbar;
