import Navbar from "./component/pages/Navbar";
import "./component/scss/Main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/pages/Home";
import "./App.scss";
import Form1 from "./component/pages/Form1";
import Page404 from "./component/pages/Page404";
import Minting from "./component/pages/Minting";
import Coming_soon from "./component/pages/Coming_soon";
import { useStoreActions } from "easy-peasy";
import { DissconnectWallet, GetChainId, MetaMasklogin, WalletConnect, getUserAddress, mainprovider } from "./Web3/web3";
import { useEffect } from "react";
import Footer from "./component/pages/Footer";
import Mint from "./component/pages/Mint";
import Team from "./component/pages/Team";
import  Leaderboard  from "./component/pages/Leaderboard";
import Contact_form from "./component/pages/Contact_form"

function App() {


  const setUser = useStoreActions((action)=> action.setUser)

 
  useEffect(() => {
    const init = async () => {

      const wallet = window.localStorage.getItem("wallet");
      if (wallet) {
        await Metamask();
      }
      const id = await GetChainId();
      console.log(id);
      if (Number(id) != 1) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }], // chainId must be in hexadecimal numbers 38
        });
      }
    };

    init();
  }, []);

  const WalletC = async () => {
    await WalletConnect();
    const add = await getUserAddress();
    setUser(add);

  };

  const Metamask = async () => {
    await MetaMasklogin();
    const add = await getUserAddress();
    window.localStorage.setItem("wallet", "wallet");
    setUser(add);
  };



  const Dissconnect = async () => {
    await DissconnectWallet();
    setUser(undefined);
    window.localStorage.removeItem("wallet");
  };

  try {
    window.ethereum.on("accountsChanged", function (accounts) {
      setUser(accounts[0]);
    });
  } catch (error) { }

  try {
    window.ethereum.on("chainChanged", function (accounts) {
      window.location.reload();
    });
  } catch (error) { }

  return (
    <div className="">
      <Router>
        <Routes>
          <Route path="/" element={<Home Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />
          <Route path="/MintPond" element={<Mint Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />
          <Route path="/team" element={<Team Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />

          <Route path="/form" element={<Form1 Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />
          <Route path="/leaderboard" element={<Leaderboard Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />
          <Route path="/ContactForm" element={<Contact_form Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />

          <Route path="/MintRacingDuck" element={<Minting Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />
          <Route path="/ComingSoon" element={<Coming_soon Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />
          <Route path="*" element={<Home Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC}/>} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
