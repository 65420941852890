import React, { useEffect, useLayoutEffect, useState } from "react";

import { ImCross } from "react-icons/im";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillYoutube, AiOutlineMenu, AiOutlineTwitter } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";

import Modal from "react-bootstrap/Modal";
import meta from "../Image/meta.png";
import twitter_new from "../Image/twitter_new.png";
import walletconnect from "../Svg/walletconnect.svg";
import link_tree from "../Image/link_tree.png";
import "animate.css";
import opensea from "../Image/opensea.png";

import logo from "../Image/nfe-logo.png";
import { Link } from "react-router-dom";

function Navbar({ Dissconnect, Metamask, WalletC }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // ---------dark---------mode----------------

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  }, []);

  const [theme, setTheme] = useState("light-theme");
  const toggleTheme = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "dark-theme"
    );
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <>
      <div className="  navbar12  d-f a-i-c j-c-s-e m-q-b-d-n p-y-1 ">
        <div className="">
          <ul className="l-s-t-n d-f a-i-c ">
            <li className="m-r-2 c-p   m-q-b-d-n">
              <Link to="/">
                <button className="b-c-t b-5 f-w-800 f-s-1_25  b-r-10 b_y_c bg_b_c w-10 h-3">
                  {" "}
                  <span className="t_g f-w-600"> HOME </span>
                </button>{" "}
              </Link>
            </li>
            <li className="m-r-2 c-p   m-q-b-d-n">
              <a
                href="https://docs.google.com/document/d/17zAWegXzlqq5ghNjWwW8LBVQIfyQ66pI3qyrht1Dnb4/edit"
                target="_blank"
              >
                <button className="b-c-t b-5 f-w-800 f-s-1_25  b-r-10 b_y_c bg_b_c w-10 h-3">
                  {" "}
                  <span className="t_g f-w-600"> ROADMAP</span>
                </button>
              </a>
            </li>
            <li className="m-r-2 c-p   m-q-b-d-n">
              <Link to="/team">
                <button className="b-c-t b-5 f-w-800 f-s-1_25  b-r-10 b_y_c bg_b_c w-10 h-3">
                  {" "}
                  <span className="t_g f-w-600"> TEAM</span>
                </button>
              </Link>
            </li>
            <li className="m-r-2 c-p   m-q-b-d-n">
              <a href="https://3deac6.myshopify.com/" target="_blank">
                <button className="b-c-t b-5 f-w-800 f-s-1_25  b-r-10 b_y_c bg_b_c w-10 h-3">
                  {" "}
                  <span className="t_g f-w-600"> SHOP</span>
                </button>
              </a>
            </li>
            <li className="m-r-2 c-p   m-q-b-d-n">
              <a href="https://discord.gg/3FSFVd3pHE" target="_blank">
                <button className="b-c-t b-5 f-w-800 f-s-1_25  b-r-10 b_y_c bg_b_c w-10 h-3">
                  {" "}
                  <span className="t_g f-w-600"> LEADERBOARD</span>
                </button>
              </a>
            </li>
            <li className="m-r-2 c-p   m-q-b-d-n">
              <a href="http://www.internetpass.info/" target="_blank">
                <button className="b-c-t b-5 f-w-800 f-s-1_25  b-r-10 b_y_c bg_b_c w-10 h-3">
                  {" "}
                  <span className="t_g f-w-600"> KYC PASSPORT</span>
                </button>
              </a>
            </li>
            <li className="m-r-2 c-p   m-q-b-d-n">
              <Link to="/ContactForm">
                <button className="b-c-t b-5 f-w-800 f-s-1_25 p-x-0_5  b-r-10 b_y_c bg_b_c  p-y-0_5">
                  {" "}
                  <span className="t_g f-w-600"> CONTACT & sponsor</span>
                </button>
              </Link>
            </li>
          </ul>
        </div>
        {/*   <div className=" c-p ">
          <ul className="l-s-t-n d-f a-i-c ">
            <li className="m-l-2 c-p   m-q-a-d-n ">
              {" "}
              <Button onClick={handleShow} className=" b-c-t c-i b-n pa-0">
                <AiOutlineMenu />
              </span></Button>
            </li>
          </ul>
        </div> */}
      </div>

      <div className="  navbar12  d-f a-i-c j-c-s-e  p-y-1 p-x-1">
        <div className="">
          <ul className="l-s-t-n d-f a-i-c ">
            <li className="m-q-m-r-2 c-p   ">
              <a href="https://www.youtube.com/@duckracederby" target="_blank">
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2 ps-r  b-r-50 m-a">
                    {" "}
                    <AiFillYoutube className="f_w h-c-v-c" />{" "}
                  </div>
                </button>{" "}
              </a>
            </li>
            <li className="m-q-m-r-2 c-p   ">
              <a href="https://twitter.com/DuckRaceDerby" target="_blank">
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2 ps-r   b-r-50 m-a">
                    {" "}
                    <img src={twitter_new}  className="f_w h-c-v-c b-r-20 w-1" />
                  </div>
                </button>
              </a>
            </li>
            <li className="m-q-m-r-2 c-p   ">
              <a href="https://discord.com/invite/7QfVfAzgyE" target="_blank">
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                    {" "}
                    <BsDiscord className="f_w h-c-v-c" />
                  </div>
                </button>
              </a>
            </li>
            <li className=" c-p   ">
              <a href="https://linktr.ee/duckracederby" target="_blank">
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25 m-q-m-r-2   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                    {" "}
                    <img
                      src={link_tree}
                      alt=""
                      className="w-1_5 h-c-v-c"
                    />{" "}
                  </div>
                </button>
              </a>
            </li>
            <li className=" c-p   ">
              <a
                href="https://opensea.io/collection/duck-race-derby-pond-2"
                target="_blank"
              >
                <button className="b-c-t b-5 f-w-800 t-a-c f-s-1_25   b-r-20 b_y_c bg_b_c  pa-0_5">
                  {" "}
                  <div className="bg_c_b w-2 h-2  ps-r b-r-50 m-a">
                    {" "}
                    <img src={opensea} alt="" className="w-1_5 h-c-v-c" />{" "}
                  </div>
                </button>
              </a>
            </li>
          </ul>
        </div>
        <div className=" c-p m-q-a-d-n ">
          <ul className="l-s-t-n d-f a-i-c ">
            <li className="m-l-2 c-p    ">
              {" "}
              <Button onClick={handleShow} className=" b-c-t c-i b-n pa-0">
                <AiOutlineMenu className="f-s-2" />
              </Button>
            </li>
          </ul>
        </div>
      </div>

      {/* ---------------mobile-navbar---------------------------------- */}

      <Offcanvas show={show} onHide={handleClose} className="pa-1">
        <Offcanvas.Body>
          <div className="t-a-r m-b-2">
            {" "}
            <ImCross onClick={handleClose} color="black" />
          </div>

          <ul className="l-s-t-n ">
            <li className=" c-p   ">
              <button
                className="b-c-t w-100 m-b-1 f-s-2 b-5 f-w-800   b-r-10 b_y_c bg_b_c p-y-0_5"
                onClick={handleClose}
              >
                {" "}
                <span className="t_g f-w-600"> HOME </span>
              </button>{" "}
            </li>
            <li className=" c-p   ">
              <a
                href="https://docs.google.com/document/d/17zAWegXzlqq5ghNjWwW8LBVQIfyQ66pI3qyrht1Dnb4/edit"
                target="_blank"
              >
                <button
                  className="b-c-t w-100 m-b-1 f-s-2 b-5 f-w-800   b-r-10 b_y_c bg_b_c p-y-0_5"
                  onClick={handleClose}
                >
                  {" "}
                  <span className="t_g f-w-600"> ROADMAP</span>
                </button>
              </a>
            </li>
            <li className=" c-p   ">
              <Link to="/team">
                <button
                  className="b-c-t w-100 m-b-1 f-s-2 b-5 f-w-800   b-r-10 b_y_c bg_b_c p-y-0_5"
                  onClick={handleClose}
                >
                  {" "}
                  <span className="t_g f-w-600"> TEAM</span>
                </button>
              </Link>
            </li>
            <li className=" c-p   ">
              <a href="https://3deac6.myshopify.com/" target="_blank">
                <button
                  className="b-c-t w-100 m-b-1 f-s-2 b-5 f-w-800   b-r-10 b_y_c bg_b_c p-y-0_5"
                  onClick={handleClose}
                >
                  {" "}
                  <span className="t_g f-w-600"> SHOP</span>
                </button>
              </a>
            </li>
            <li className=" c-p   ">
              <a href="https://discord.gg/3FSFVd3pHE" target="_blank">
                <button
                  className="b-c-t w-100 m-b-1 f-s-2 b-5 f-w-800   b-r-10 b_y_c bg_b_c p-y-0_5"
                  onClick={handleClose}
                >
                  {" "}
                  <span className="t_g f-w-600"> LEADERBOARD</span>
                </button>
              </a>
            </li>
            <li className=" c-p   ">
              <a href="http://www.internetpass.info/" target="_blank">
                <button
                  className="b-c-t w-100 m-b-1 f-s-2 b-5 f-w-800   b-r-10 b_y_c bg_b_c p-y-0_5"
                  onClick={handleClose}
                >
                  {" "}
                  <span className="t_g f-w-600"> KYC PASSPORT</span>
                </button>
              </a>
            </li>
            <li className=" c-p   ">
              <Link to="/ContactForm">
                <button
                  className="b-c-t w-100 m-b-1 f-s-2 b-5 f-w-800   b-r-10 b_y_c bg_b_c p-y-0_5"
                  onClick={handleClose}
                >
                  {" "}
                  <span className="t_g f-w-600"> CONTACT & SPONSORS</span>
                </button>
              </Link>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={show2} onHide={handleClose2} className="">
        <div className="">
          <Modal.Header closeButton>
            <Modal.Title className=" c-i">
              Please Connect Your Wallet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
              <div className="walletconnect d-f a-i-c j-c-s-e m-y-2">
                <img
                  src={meta}
                  alt=""
                  className="w-7"
                  onClick={() => {
                    Metamask();
                    handleClose2();
                  }}
                />
                <img
                  src={walletconnect}
                  alt=""
                  className="w-5"
                  onClick={() => {
                    WalletC();
                    handleClose2();
                  }}
                />
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Navbar;
