import { createStore, action } from 'easy-peasy';

const MainStore = createStore({
    user: undefined,
    setUser: action((state, payload) => {
        state.user = payload;
    }),
    email_api_key:"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",
  });
  
  export default MainStore;
